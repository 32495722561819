import Link, { LinkProps } from "@/components/resume/links/Link";

import Icon from "@/components/resume/icons/Icon";
import { JSX } from "react";
import { isNilOrEmpty } from "@/utils";

type IconLinkProps = LinkProps & {
  iconClassName?: string;
  iconText?: string;
};

const IconLink = ({
  href,
  className,
  iconClassName,
  iconText,
  target = "_self",
  children,
  ...restProps
}: IconLinkProps): JSX.Element | null => {
  if (!href || isNilOrEmpty(href)) {
    return null;
  }

  return (
    <Link
      href={href}
      className={className}
      linkText={<Icon className={iconClassName} iconText={iconText} />}
      target={target}
      {...restProps}
    >
      {children}
    </Link>
  );
};

export default IconLink;
