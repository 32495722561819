import "@/components/form/ResumeDarkModeSelect.scss";
import { ChangeEventHandler, ComponentProps, JSX } from "react";
import { ResumeThemeType } from "@/types/resume";
import { buildDarkModeSelectOptions } from "@/utils";
import classNames from "classnames";

type ResumeDarkModeSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  onResumeDarkModeSelect?: (value: ResumeThemeType) => void;
  className?: string;
};

const ResumeDarkModeSelect = ({
  hideSelect = true,
  onResumeDarkModeSelect,
  className,
  ...restProps
}: ResumeDarkModeSelectProps): JSX.Element | null => {
  if (hideSelect) {
    return null;
  }

  const resumeDarkModeSelectOptions = buildDarkModeSelectOptions({
    noOptions: hideSelect,
  });

  const onResumeDarkModeSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selected = e.target.value as ResumeThemeType;

    return onResumeDarkModeSelect && onResumeDarkModeSelect(selected);
  };

  if (resumeDarkModeSelectOptions.length < 2) {
    return null;
  }

  return (
    <select
      {...restProps}
      name="resume-dark-mode-selector"
      className={classNames("cp-resume-dark-mode-select", className)}
      onChange={onResumeDarkModeSelectChange}
      title="Which color mode? Light, Dark, or Auto?"
    >
      {resumeDarkModeSelectOptions.map((option) => {
        return (
          <option value={option.value} key={`resume-dark-mode-selector-option-${option.value}`}>
            {option.text}
          </option>
        );
      })}
    </select>
  );
};

export default ResumeDarkModeSelect;
