import { ReactNode } from "react";
import getValidChildren from "@/utils/app/getValidChildren";
import isEmpty from "@/utils/base/isEmpty";
import isNil from "@/utils/base/isNil";
import isString from "@/utils/base/isString";

const nodesEmpty = (nodes: ReactNode) =>
  isNil(nodes) || (isString(nodes) && isEmpty(nodes)) || getValidChildren(nodes).length < 1;

export default nodesEmpty;
