import { ResumeSelectOption, ResumeSelectOptionItem } from "@/types/base";

const buildResumeSelectOption = (value: ResumeSelectOption, text: string): ResumeSelectOptionItem => ({ value, text });

type BuildResumeSelectOptionsProps = {
  noOptions?: boolean;
  hideResumeOption?: boolean;
  hideFullResumeOption?: boolean;
  hideDemoOptions?: boolean;
};

const buildResumeSelectOptions = ({
  noOptions = false,
  hideResumeOption = false,
  hideFullResumeOption = false,
  hideDemoOptions = true,
}: BuildResumeSelectOptionsProps) => {
  const resumeSelectOptions: ResumeSelectOptionItem[] = [];

  if (!noOptions) {
    !hideFullResumeOption && resumeSelectOptions.push(buildResumeSelectOption("res-full-config", "Full"));
    !hideResumeOption && resumeSelectOptions.push(buildResumeSelectOption("res-brief-config", "Brief"));
    !hideDemoOptions &&
      resumeSelectOptions.push(
        buildResumeSelectOption("demo-res-full-config", "Demo Full"),
        buildResumeSelectOption("demo-res-brief-config", "Demo Brief")
      );
  }
  return resumeSelectOptions;
};

export default buildResumeSelectOptions;
