import { useEffect, useState } from "react";
import { ResumeThemeType } from "@/types/resume";
import includesSupportedTheme from "@/utils/theme/includesSupportedTheme";
import isNil from "@/utils/base/isNil";

type useSideWheelScrollProps = {
  scrollEl?: HTMLElement | null;
  theme?: ResumeThemeType;
  scrollSegmentDenominator?: number;
  wideThemeBreakpoint?: number;
};

type useSideWheelScrollReturnProps = {
  scrollEl?: HTMLElement | null;
};

const useSideWheelScroll = ({
  scrollEl,
  scrollSegmentDenominator = 16,
  wideThemeBreakpoint = 1367,
  theme,
}: useSideWheelScrollProps): useSideWheelScrollReturnProps => {
  const [listening, setListening] = useState(false);
  const isSideScrolling = includesSupportedTheme({ theme, themeSearch: "side-scroll" });

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const isScreenWide = window.innerWidth > wideThemeBreakpoint;
      if (isScreenWide && !isNil(scrollEl)) {
        const isDeltaYNeg = e.deltaY < 0;
        const isDeltaYPos = e.deltaY > 0;

        const scrollSegmentWidth = (scrollEl?.scrollWidth ?? 0) / scrollSegmentDenominator;
        const scrollToLeftSegment = isDeltaYNeg ? -scrollSegmentWidth : scrollSegmentWidth;
        const isAtBeginning = scrollEl.scrollLeft === 0;
        const isAtEnd = scrollEl.scrollLeft + scrollEl.clientWidth >= scrollEl.scrollWidth;
        const hasScrollbars = scrollEl.scrollWidth > scrollEl.clientWidth;

        hasScrollbars ? scrollEl?.classList.add("has-scrollbars") : scrollEl?.classList.remove("has-scrollbars");

        // check if the user is at the beginning or has reached the end of the scroll slider
        isAtBeginning && isDeltaYNeg && hasScrollbars
          ? scrollEl?.classList.add("scroll-begin")
          : scrollEl?.classList.remove("scroll-begin");

        isAtEnd && isDeltaYPos && hasScrollbars
          ? scrollEl?.classList.add("scroll-end")
          : scrollEl?.classList.remove("scroll-end");

        const scrollToLeft = Math.round(Math.max(scrollEl.scrollLeft + scrollToLeftSegment, 0));
        scrollEl?.scrollTo({ left: scrollToLeft });
      }
    };

    if (!isNil(scrollEl)) {
      scrollEl.removeEventListener("wheel", handleWheel);
      const hasScrollbars = scrollEl.scrollWidth > scrollEl.clientWidth;

      if (isSideScrolling && !listening && !!scrollEl.addEventListener) {
        setListening((currentValue) => currentValue);
        scrollEl.addEventListener("wheel", handleWheel, { passive: true });
      }

      if (isSideScrolling || listening) {
        scrollEl.classList.add("cp-resume-scroll-el");
        scrollEl.style.scrollSnapType = "none";

        hasScrollbars
          ? scrollEl?.classList.add("has-scrollbars")
          : scrollEl?.classList.remove("scroll-begin", "scroll-end", "has-scrollbars");
        scrollEl.scrollLeft === 0 && scrollEl.classList.add("scroll-begin");
      } else {
        scrollEl.style.transition = "none";
        scrollEl.style.scrollSnapType = "x proximity";
        scrollEl?.classList.remove("cp-resume-scroll-el", "scroll-begin", "scroll-end", "has-scrollbars");
      }
    }

    return () => {
      (!isSideScrolling || !listening) && scrollEl?.removeEventListener("wheel", handleWheel);
    };
  }, [isSideScrolling, listening, scrollEl, scrollSegmentDenominator, wideThemeBreakpoint, theme]);

  return {
    scrollEl,
  };
};

export default useSideWheelScroll;
