import { IconSetType, ResumeWordDocUrl } from "@/types/resume";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

type ToolbarWordDocOptionLinkProps = {
  iconSet: IconSetType;
  resumeWordDocUrl?: ResumeWordDocUrl;
};

const ToolbarWordDocOptionLink = ({ iconSet, resumeWordDocUrl }: ToolbarWordDocOptionLinkProps) => {
  if (isNilOrEmpty(resumeWordDocUrl)) {
    return null;
  }

  return (
    <a
      href={resumeWordDocUrl}
      title="Click to view Resume Word Document"
      target="_blank"
      rel="noopener noreferrer"
      className="cp-resume-toolbar-option cp-resume-word"
    >
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.fileWord)} />
      <span className="sr-only">View Word Document</span>
    </a>
  );
};

export default ToolbarWordDocOptionLink;
