import { ResumeConfigType, ResumeType } from "@/types/resume";
import { DefaultResumeDemoUrl } from "@/consts";
import { ResumeSelectOption } from "@/types/base";
import getResumeConfigFromDefault from "@/utils/resume/getResumeConfigFromDefault";
import resumeDemoBriefConfigJson from "@/data/resumeDemoBriefConfig.json";
import resumeDemoFullConfigJson from "@/data/resumeDemoFullConfig.json";
import useFetchResume from "@/utils/resume/useFetchResume";

const ResumeDemoBriefConfigDefaults = getResumeConfigFromDefault(resumeDemoBriefConfigJson as ResumeConfigType);
const ResumeDemoFullConfigDefaults = getResumeConfigFromDefault(resumeDemoFullConfigJson as ResumeConfigType);

type UseDemoConfigProps = {
  resumeDemoBriefConfig?: ResumeConfigType;
  resumeDemoFullConfig?: ResumeConfigType;
  selectedResume?: ResumeSelectOption;
  demo?: boolean;
};

type UseDemoConfigReturnProps = {
  demoConfig?: ResumeConfigType;
  isDemo?: boolean;
  resumeDemoData?: ResumeType;
};
const useDemoConfig = ({
  resumeDemoBriefConfig = ResumeDemoBriefConfigDefaults,
  resumeDemoFullConfig = ResumeDemoFullConfigDefaults,
  selectedResume,
  demo,
}: UseDemoConfigProps): UseDemoConfigReturnProps => {
  const isDemo = selectedResume ? selectedResume.includes("demo") : false;
  const demoConfig = demo
    ? selectedResume === "demo-res-full-config"
      ? resumeDemoFullConfig
      : selectedResume === "demo-res-brief-config"
        ? resumeDemoBriefConfig
        : undefined
    : undefined;

  const { resumeData: resumeDemoData } = useFetchResume({ resumeUrl: DefaultResumeDemoUrl, isGated: !demo });

  return {
    demoConfig,
    isDemo,
    resumeDemoData,
  };
};

export default useDemoConfig;
