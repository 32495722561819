import { JSX } from "react";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { ReferenceItemType } from "@/types/resume";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type ReferenceItemProps = {
  referenceItem: ReferenceItemType;
};

const ReferenceItem = ({ referenceItem }: ReferenceItemProps): JSX.Element | null => {
  const { pipes } = useResumeConfig();

  if (isNil(referenceItem) || referenceItem.hide) {
    return null;
  }

  const referenceParagraphs = referenceItem.reference?.split("\n").map((paragraph, index) => (
    <p
      className="cp-resume-reference-p"
      key={`ref-p-${index}-${referenceItem.name.replace(" ", "-")}-${paragraph.length}`}
    >
      {paragraph}
    </p>
  ));

  return (
    <li className="cp-resume-reference-item">
      <Pipes className="cp-resume-reference-info" blockOnMobile={true}>
        <PipeItem itemValue={referenceItem.name} itemClassName="cp-resume-reference-name" itemType={pipes} />
        <PipeItem
          itemValue={<>{referenceParagraphs}</>}
          itemClassName="cp-resume-reference-reference"
          itemType={pipes}
        />
      </Pipes>
    </li>
  );
};

export default ReferenceItem;
