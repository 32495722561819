import "@/components/resume/header/Header.scss";

import { JSX, ReactNode, useEffect } from "react";
import { LabelType, ResumeTitleEndTextType } from "@/types/resume";
import HeaderJobLabel from "@/components/resume/header/HeaderJobLabel";
import { getResumeTitle } from "@/utils";

type HeaderProps = {
  jobLabel?: LabelType;
  fullName?: string;
  resumeTitleEndText?: ResumeTitleEndTextType;
  contactInfo?: ReactNode;
};

const Header = ({ jobLabel, fullName, contactInfo, resumeTitleEndText }: HeaderProps): JSX.Element | null => {
  useEffect(() => {
    document.title = getResumeTitle({ jobLabel, fullName, resumeTitleEndText });
  }, [jobLabel, fullName, resumeTitleEndText]);

  return (
    <header className="cp-resume-header">
      <h1 className="cp-resume-title">
        {fullName} {!!jobLabel && <HeaderJobLabel jobLabel={jobLabel} />}
      </h1>

      {contactInfo}
    </header>
  );
};

export default Header;
