import "@/components/resume/locations/Location.scss";

import { IconSetType, LocationType, ResumeMapApiType, ResumeViewableType } from "@/types/resume";
import { checkKeyViewable, transformBase64, useLocation } from "@/utils";

import { DefaultIconSet } from "@/consts";
import Icon from "@/components/resume/icons/Icon";
import { JSX } from "react";
import Link from "@/components/resume/links/Link";
import { isNilOrEmpty } from "@/utils";

type LocationProps = {
  location?: LocationType;
  viewable?: ResumeViewableType[];
  iconSet: IconSetType;
  mapApi?: ResumeMapApiType;
  asLink?: boolean;
};

const Location = ({
  location,
  viewable = [],
  iconSet = DefaultIconSet,
  mapApi,
  asLink = true,
}: LocationProps): JSX.Element | null => {
  const showLocation = checkKeyViewable("basics.location", viewable);

  const {
    hasLocation = false,
    hasAddress = false,
    hasCity = false,
    hasRegion = false,
    hasCountryCode = false,
    hasPostalCode = false,
    locationUrl,
  } = useLocation({ showLocation, location, mapApi });

  if (isNilOrEmpty(location) || isNilOrEmpty(iconSet)) {
    return null;
  }

  const address = hasAddress ? transformBase64({ toTransform: location?.address }) : undefined;

  const buildAddress = () => {
    return (
      <address className="cp-resume-address">
        <Icon className={iconSet.location} iconText="Location:" aria-hidden={true} />

        <span className="cp-resume-location-detail">
          {hasAddress && <span className="cp-resume-location-item cp-resume-location-address">{address},</span>}
          {hasCity && <span className="cp-resume-location-item cp-resume-location-city">{location?.city},</span>}
          {hasPostalCode && (
            <span className="cp-resume-location-item cp-resume-location-postal-code">{location?.postalCode},</span>
          )}
          <span className="cp-resume-location-item cp-resume-location-region-and-country-code">
            {hasRegion && <span className="cp-resume-location-region">{location?.region}</span>}
            {hasRegion && hasCountryCode && <span className="cp-resume-location-item-pipe">-</span>}
            {hasCountryCode && <span className="cp-resume-location-country-code">{location?.countryCode}</span>}
          </span>
        </span>
      </address>
    );
  };

  return showLocation && hasLocation ? (
    <>
      {asLink ? (
        <Link href={locationUrl} target={"_blank"} className="cp-resume-location" linkText={buildAddress()} />
      ) : (
        <div className="cp-resume-location">{buildAddress()}</div>
      )}
    </>
  ) : null;
};

export default Location;
