import { DefaultMapApi } from "@/consts";

const getMapUrl = (locationQuery: string, mapApi = DefaultMapApi) => {
  // NOTE: Current base Default API docs
  // https://developers.google.com/maps/documentation/urls/get-started#search-action
  // https://www.google.com/maps/search/?api=1&query=(URLEncodedString)

  const query = locationQuery ? locationQuery : "";
  return `${mapApi}${encodeURIComponent(query)}`;
};

export default getMapUrl;
