import { IconSetType } from "@/types/resume";
import IconWithText from "@/components/resume/icons/IconWithText";
import { JSX } from "react";
import { LinkProps } from "@/components/resume/links/Link";
import ObscureLink from "@/components/resume/links/ObscureLink";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

type TelLinkProps = Omit<LinkProps, "href"> & {
  phone?: string;
  iconSet: IconSetType;
};

const TelLink = ({
  phone,
  className,
  target = "_self",
  iconSet,
  children,
  ...restProps
}: TelLinkProps): JSX.Element | null => {
  if (!phone || isNilOrEmpty(phone)) {
    return null;
  }
  const telLinkHref = `tel:${phone}`;

  return (
    <ObscureLink
      href={telLinkHref}
      className={classNames("cp-resume-tel-link", className)}
      linkText={<IconWithText iconText="Phone: " iconClassName={iconSet.phone} text={phone} />}
      target={target}
      {...restProps}
    >
      {children}
    </ObscureLink>
  );
};

export default TelLink;
