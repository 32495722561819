import "@/components/toolbar/ToolbarBuiltWithLink.scss";

import { ComponentProps } from "react";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

export type ToolbarBuildWithLinkProps = ComponentProps<"a"> & {
  logoSrc: string;
  logoAlt: string;
  linkText: string;
};
const ToolbarBuildWithLink = ({
  logoSrc,
  logoAlt,
  linkText,
  href,
  className,
  target = "_blank",
  ...restProps
}: ToolbarBuildWithLinkProps) => {
  if (isNilOrEmpty(href)) {
    return null;
  }

  return (
    <a
      href={href}
      className={classNames("cp-resume-toolbar-built-with-link", className)}
      title="ReactJS"
      target={target}
      {...restProps}
    >
      <img className="cp-resume-toolbar-built-with-logo" src={logoSrc} alt={logoAlt} />
      <span className="sr-only">{linkText}</span>
    </a>
  );
};

export default ToolbarBuildWithLink;
