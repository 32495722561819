import getBase64String from "@/utils/b64/getBase64String";
import isString from "@/utils/base/isString";

type Base64StringProps = {
  toTransform?: string;
  prefixKey?: string;
};

const transformBase64 = ({ toTransform, prefixKey = "b64-" }: Base64StringProps) => {
  return isString(toTransform) && toTransform.startsWith(prefixKey)
    ? getBase64String({ value: toTransform.replace(prefixKey, "") })
    : toTransform;
};

export default transformBase64;
