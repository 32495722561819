import { JSX } from "react";
import { SkillType } from "@/types/resume";
import { isNil } from "@/utils";

type SkillItemProps = {
  skill?: SkillType;
  asTable?: boolean;
};

const SkillItem = ({ skill, asTable = true }: SkillItemProps): JSX.Element | null => {
  if (isNil(skill) || !skill?.name || skill.hide) {
    return null;
  }

  const SkillItemEl = asTable ? "td" : "li";

  const skillTitle = `Skill: ${skill.name}`;
  const levelTitle = `Level: ${skill.level}`;

  return (
    <SkillItemEl className="cp-resume-skill" aria-label={`${skillTitle}, ${levelTitle}`}>
      <div className="cp-resume-skill-name" title={skillTitle}>
        {skill.name}
      </div>
      <div className="cp-resume-skill-level" title={levelTitle}>
        {skill.level}
      </div>
    </SkillItemEl>
  );
};

export default SkillItem;
