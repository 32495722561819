/**
 *
 * @param el {HTMLElement}
 */
const clipElement = (el: HTMLElement) => {
  try {
    const range = document.createRange();
    range.selectNodeContents(el);
    const windowSelection = window.getSelection();
    windowSelection?.removeAllRanges();
    windowSelection?.addRange(range);

    navigator.clipboard.writeText(el.innerText).catch((reason) => {
      console.log("Sorry! Unable to clipboard.writeText", reason);
    });
  } catch (reason) {
    //do nothing
    console.log("Sorry! Unable to clipboard", reason);
  }
};

export default clipElement;
