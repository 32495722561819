import { LocationType, ResumeMapApiType } from "@/types/resume";
import getResumeLocationUrl from "@/utils/location/getResumeLocationUrl";
import isEmpty from "@/utils/base/isEmpty";
import isNil from "@/utils/base/isNil";

const hasBasicProfileItem = (show = false, item = "") => {
  return show && !isNil(item) && !isEmpty(item);
};

type UseLocationProps = {
  showLocation?: boolean;
  location?: LocationType;
  mapApi?: ResumeMapApiType;
};

type UseLocationReturnProps = {
  hasLocation: boolean;
  hasAddress: boolean;
  hasCity: boolean;
  hasPostalCode: boolean;
  hasCountryCode: boolean;
  hasRegion: boolean;
  hasPhone: boolean;
  location?: LocationType;
  locationUrl?: string;
};
const useLocation = ({ showLocation = false, location, mapApi }: UseLocationProps): UseLocationReturnProps => {
  const show = location?.hide !== true && showLocation;
  const hasAddress = hasBasicProfileItem(show, location?.address);
  const hasCity = hasBasicProfileItem(show, location?.city);
  const hasPostalCode = hasBasicProfileItem(show, location?.postalCode);
  const hasCountryCode = hasBasicProfileItem(show, location?.countryCode);
  const hasRegion = hasBasicProfileItem(show, location?.region);
  const hasPhone = hasBasicProfileItem(show, location?.phone);
  const hasLocation =
    location?.hide !== true && (hasAddress || hasCity || hasPostalCode || hasCountryCode || hasRegion);
  const locationUrl = getResumeLocationUrl(location, mapApi);

  return {
    hasLocation,
    hasAddress,
    hasCity,
    hasPostalCode,
    hasCountryCode,
    hasRegion,
    hasPhone,
    location,
    locationUrl,
  };
};

export default useLocation;
