import "@/components/resume/CPResume.scss";

import { BasicsType, ResumeConfigType, ResumeThemeType, ResumeType } from "@/types/resume";
import { DetailedHTMLProps, HTMLAttributes, JSX } from "react";
import { ResumeConfigProvider, useResumeConfig } from "@/context/resume";

import { checkKeyViewable, getViewableKeyValue, isArray, isNil, isNull, isSupportedTheme, isUndefined } from "@/utils";
import Basics from "@/components/resume/sections/Basics";
import Detail from "@/components/resume/detail/Detail";
import Header from "@/components/resume/header/Header";
import classNames from "classnames";

const DefaultResumeConfig = {
  theme: "base",
};

export type CPResumeDisplayProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  resumeData: ResumeType;
  className?: string;
};

const CPResumeDisplay = ({ resumeData, className, ...restProps }: CPResumeDisplayProps): JSX.Element | null => {
  const { resumeConfig, theme, viewable } = useResumeConfig();

  if (isNil(resumeData) || isUndefined(resumeData) || isNull(resumeConfig) || isUndefined(resumeConfig)) {
    return null;
  }

  const basics: BasicsType = getViewableKeyValue("basics", resumeData, viewable);

  const themeClassNames = isArray(theme)
    ? theme.map((t) => (isSupportedTheme(t) ? `cp-resume-theme-${t}` : t)).join(" ")
    : undefined;

  return (
    <div
      {...restProps}
      className={classNames("cp-resume", className, themeClassNames, {
        [`${theme}`]: theme && !isArray(theme) && !isSupportedTheme(theme),
        [`cp-resume-theme-${theme}`]: theme && !isArray(theme) && isSupportedTheme(theme) && theme !== "wide",
        "cp-resume-theme-wide":
          (theme && isSupportedTheme(theme) && theme?.includes("wide")) ||
          (isArray(theme) &&
            theme.filter((t) => {
              return isSupportedTheme(t) && t.includes("wide");
            }).length > 0),
      })}
    >
      <Header
        contactInfo={<Basics basics={basics} />}
        fullName={checkKeyViewable("basics.name", viewable) ? basics?.name : undefined}
        jobLabel={checkKeyViewable("basics.label", viewable) ? basics?.label : undefined}
        resumeTitleEndText={resumeConfig.resumeTitleEndText}
      />

      <Detail
        summaryText={checkKeyViewable("basics.summary", viewable) ? basics?.summary : undefined}
        skills={getViewableKeyValue("skills", resumeData, viewable)}
        education={getViewableKeyValue("education", resumeData, viewable)}
        work={getViewableKeyValue("work", resumeData, viewable)}
        volunteer={getViewableKeyValue("volunteer", resumeData, viewable)}
        awards={getViewableKeyValue("awards", resumeData, viewable)}
        certificates={getViewableKeyValue("certificates", resumeData, viewable)}
        publications={getViewableKeyValue("publications", resumeData, viewable)}
        languages={getViewableKeyValue("languages", resumeData, viewable)}
        interests={getViewableKeyValue("interests", resumeData, viewable)}
        references={getViewableKeyValue("references", resumeData, viewable)}
        projects={getViewableKeyValue("projects", resumeData, viewable)}
        custom={getViewableKeyValue("custom", resumeData, viewable)}
      />
    </div>
  );
};

export type CPResumeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  resumeConfig?: ResumeConfigType | undefined;
  resumeData: ResumeType;
  className?: string;
  themeOverride?: ResumeThemeType;
};

const CPResume = ({
  resumeData,
  resumeConfig = DefaultResumeConfig,
  themeOverride,
  className,
  ...restProps
}: CPResumeProps): JSX.Element | null => {
  if (isNil(resumeData) || isUndefined(resumeData) || isNull(resumeConfig) || isUndefined(resumeConfig)) {
    return null;
  }

  return (
    <ResumeConfigProvider config={resumeConfig} themeOverride={themeOverride}>
      <CPResumeDisplay resumeData={resumeData} className={className} {...restProps} />
    </ResumeConfigProvider>
  );
};

export default CPResume;
