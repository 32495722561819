import "@/components/resume/icons/IconWithText.scss";

import { ComponentProps, JSX } from "react";
import Icon from "@/components/resume/icons/Icon";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

type IconProps = ComponentProps<"span"> & {
  className?: string;
  iconClassName?: string;
  text?: string;
  iconText?: string;
  iconAriaHidden?: boolean;
};

const IconWithText = ({
  className,
  iconClassName,
  text,
  iconText,
  iconAriaHidden = true,
  ...restProps
}: IconProps): JSX.Element | null => {
  if (isNilOrEmpty(iconClassName) || (isNilOrEmpty(iconText) && isNilOrEmpty(text))) {
    return null;
  }

  return (
    <span className={classNames("cp-resume-icon-with-text", className)} {...restProps}>
      <Icon className={iconClassName} iconText={iconText} aria-hidden={iconAriaHidden} />
      {text && <span className="cp-resume-icon-text">{text}</span>}
    </span>
  );
};

export default IconWithText;
