import isNil from "@/utils/base/isNil";

type Base64StringProps = {
  value: string;
  decrypt?: boolean;
  fallback?: "value" | undefined;
};

const getBase64String = ({ value, decrypt = true, fallback = "value" }: Base64StringProps) => {
  if (isNil(value)) {
    return value;
  }

  try {
    return decrypt ? atob(value) : btoa(value);
  } catch (_) {
    //do nothing
  }
  return fallback === "value" ? value : undefined;
};

export default getBase64String;
