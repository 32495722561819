import isString from "@/utils/base/isString";

type CreateKeyFromOptionsType = {
  join?: string;
  searchRegEx?: RegExp | string;
};

const createKeyFrom = (value: string, options?: CreateKeyFromOptionsType): string =>
  isString(value) ? value.replace(options?.searchRegEx ?? /\s/g, options?.join ?? "-") : value;

export default createKeyFrom;
