import { DateMonthStringsSet } from "@/consts";
import { DateTextType } from "@/types/resume";
import isDate from "@/utils/base/isDate";
import isEmpty from "@/utils/base/isEmpty";
import isNil from "@/utils/base/isNil";

type ConvertDateType = DateTextType & {
  noDate?: string;
};

const convertDate = ({ date, noDate, months = DateMonthStringsSet }: ConvertDateType) => {
  if (isEmpty(date) || isNil(date)) {
    return noDate;
  }

  const dateObj = new Date(date);

  return !isDate(dateObj) ? noDate : `${months[dateObj.getUTCMonth()]}/${dateObj.getUTCFullYear()}`;
};

export default convertDate;
