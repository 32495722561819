import { LabelType, ResumeTitleEndTextType } from "@/types/resume";
import isString from "@/utils/base/isString";

type GetResumeTitleProps = {
  jobLabel?: LabelType;
  fullName?: string;
  resumeTitleEndText?: ResumeTitleEndTextType;
  fallbackText?: string;
};

const getResumeTitle = ({
  fullName,
  jobLabel,
  resumeTitleEndText = " - Resume",
  fallbackText = "Resume",
}: GetResumeTitleProps) => {
  if (!isString(fullName)) {
    return fallbackText;
  }
  const jobLabelText = jobLabel ? ` (${jobLabel})` : "";
  return `${fullName}${jobLabelText}${resumeTitleEndText}`;
};

export default getResumeTitle;
