import { ResumeThemeOptionItem, ResumeThemeType } from "@/types/resume";

const buildDarkModeOption = (value: ResumeThemeType, text: string): ResumeThemeOptionItem => ({ value, text });

type BuildResumeDarkModeSelectOptionsProps = {
  noOptions?: boolean;
};

const buildDarkModeSelectOptions = ({ noOptions = false }: BuildResumeDarkModeSelectOptionsProps) => {
  const resumeDarkModeSelectOptions: ResumeThemeOptionItem[] = [];

  !noOptions &&
    resumeDarkModeSelectOptions.push(
      buildDarkModeOption("", "Light"),
      buildDarkModeOption("force-dark", "Dark"),
      buildDarkModeOption("supports-dark", "Auto")
    );

  return resumeDarkModeSelectOptions;
};

export default buildDarkModeSelectOptions;
