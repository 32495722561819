import { ResumeType, ResumeViewableType } from "@/types/resume";
import checkKeyViewable from "@/utils/resume/checkKeyViewable";
import isArray from "@/utils/base/isArray";
import isObject from "@/utils/base/isObject";

//NOTE: This finds and clones an object or array inside the full resume object
//FIXME: Due to the any nature of the cloning step have to use ts-ignore
const getViewableKeyValue = (keyName: ResumeViewableType, resume: ResumeType, viewable: ResumeViewableType[] = []) =>
  isObject(resume) && isArray(viewable) && checkKeyViewable(keyName, viewable)
    ? //FIXME: Remove ts-ignore below to fix 'any' issue
      // @ts-expect-error any is acceptable for now
      isArray(resume[keyName])
      ? //FIXME: Remove ts-ignore below to fix 'any' issue
        // @ts-expect-error any is acceptable for now
        [...resume[keyName]]
      : //FIXME: Remove ts-ignore below to fix 'any' issue
        // @ts-expect-error any is acceptable for now
        Object.assign({}, resume[keyName])
    : null;

export default getViewableKeyValue;
