import isNil from "@/utils/base/isNil";

const stripProtocol = (url?: string, protocolRegEx?: RegExp) => {
  if (isNil(url)) {
    return url;
  }

  const siteProtocolRegex: RegExp = protocolRegEx ?? /^(https?|http):\/\//;

  return url.replace(siteProtocolRegex, "");
};

export default stripProtocol;
